/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

import "./app.scss";
import "./app-mobile.scss";
import "../utils/header.js";
import "../fonts/fontface.scss";

window.addEventListener("DOMContentLoaded", function (event) {
  document.querySelector(".loading-first").classList.add("hided");
});

const FontFaceObserver = require("fontfaceobserver");

const fontMontserrat = new FontFaceObserver("Montserrat Regular");
const fontMontserratBold = new FontFaceObserver("Montserrat Bold");

Promise.all([fontMontserrat.load(), fontMontserratBold.load()]).then(
  function () {
    document.documentElement.style.setProperty(
      "--bs-font-sans-serif",
      "Montserrat Regular, sans-serif"
    );
    document.documentElement.style.setProperty(
      "--bs-font-sans-serif-bold",
      "Montserrat bold, sans-serif"
    );
  }
);

//add icon font Awesome
import { fontAwesomeLoad } from "root/utils/fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkedAlt";
import { faGlobeAsia } from "@fortawesome/free-solid-svg-icons/faGlobeAsia";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTags } from "@fortawesome/free-solid-svg-icons/faTags";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";

fontAwesomeLoad(".fa-facebook-f", faFacebookF);
fontAwesomeLoad(".fa-twitter", faTwitter);
fontAwesomeLoad(".fa-linkedin-in", faLinkedinIn);
fontAwesomeLoad(".fa-envelope", faEnvelope);
fontAwesomeLoad(".fa-phone-alt", faPhoneAlt);
fontAwesomeLoad(".fa-map-marker-alt", faMapMarkedAlt);
fontAwesomeLoad(".fa-facebook", faFacebookSquare);
fontAwesomeLoad(".fa-globe-asia", faGlobeAsia);
fontAwesomeLoad(".fa-tags", faTags);
fontAwesomeLoad(".fa-folder-open", faFolderOpen);
fontAwesomeLoad(".fa-check-circle", faCheckCircle);

//accordion
const accordion = document.getElementsByClassName("accordion-box");
for (var i = 0; i < accordion.length; i++) {
  //accordion[0].classList.toggle('active');
  accordion[i].addEventListener("click", function () {
    this.classList.toggle("active");
  });
}

//click show form search
let _element = document.getElementById("search_btn");
let _class = document.querySelector(".search_toggle");
_element.addEventListener("click", function (event) {
  event.preventDefault();
  _class.classList.toggle("show");
});
