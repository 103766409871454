// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./montserrat-regular.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./montserrat-regular.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "./montserrat-regular.ttf";
import ___CSS_LOADER_URL_IMPORT_3___ from "./montserrat-bold.woff2";
import ___CSS_LOADER_URL_IMPORT_4___ from "./montserrat-bold.woff";
import ___CSS_LOADER_URL_IMPORT_5___ from "./montserrat-bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Montserrat Regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400;font-display:swap}@font-face{font-family:Montserrat Bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-display:swap}", "",{"version":3,"sources":["webpack://src/fonts/fontface.scss"],"names":[],"mappings":"AAaA,WACE,8BAAA,CACA,6KAAA,CAGA,eAAA,CACA,iBAPF,CAUA,WACE,2BAAA,CACA,6KAAA,CAGA,iBAVF","sourcesContent":["\n      $grid-breakpoints: (\n     xs:0,sm:576px,md:768px,lg:992px,xl:1200px,xxl:1400px\n    ) !default;\n    @import \"root/scss/utils/bootstrap\";\n/**\n * @package     Wordpress.Site\n * @subpackage  Templates.redWEB\n *\n * @copyright   Copyright (C) 2020 redWEB. All rights reserved.\n * @license     GNU General Public License version 2 or later; see LICENSE.txt\n */\n\n@font-face {\n  font-family: \"Montserrat Regular\";\n  src: url(\"./montserrat-regular.woff2\") format(\"woff2\"),\n    url(\"./montserrat-regular.woff\") format(\"woff\"),\n    url(\"./montserrat-regular.ttf\") format(\"truetype\");\n  font-weight: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Montserrat Bold\";\n  src: url(\"./montserrat-bold.woff2\") format(\"woff2\"),\n    url(\"./montserrat-bold.woff\") format(\"woff\"),\n    url(\"./montserrat-bold.ttf\") format(\"truetype\");\n  font-display: swap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
