/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
import { viewport } from "root/utils/viewport";

if (document.body.contains(document.getElementById("menu-btn"))) {
  document.getElementById("menu-btn").addEventListener("click", (event) => {
    document.querySelector("body").classList.toggle("menu-open");
    document.querySelector(".search_toggle").classList.remove("show");
  });
}
